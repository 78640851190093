<template>
  <b-container class="kt-container" fluid>
    <b-row v-if="!isAdmin()">
      <b-col xs="12">
        <h2 class="text-left pl-5">Forbidden</h2>
      </b-col>
    </b-row>

    <b-row v-if="isAdmin()">
      <b-col class="mt-5 mt-md-5 mt-lg-0">
        <Listing
          v-bind:showEdit="true"
          v-bind:showDelete="true"
          v-bind:showAddNew="true"
          v-bind:crudConfig="crudConfig"
        ></Listing>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import Listing from "@/components/Listing";
import { mapGetters } from "vuex";

export default {
  name: "configuration",
  data() {
    return {
      crudConfig: {
        type: "company",
        apiRoute: "companies",
        fields: [
          {
            key: "checkbox",
            sortable: false,
            sortDirection: "desc",
            class: "text-center",
            tdClass: "text-center align-middle p-0"
          },
          {
            key: "name",
            label: "Name",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "contact",
            label: "Contact",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "phone",
            label: "Phone",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "rcs",
            label: "RCS",
            sortable: true,
            sortDirection: "desc",
            class: "text-center"
          },
          {
            key: "vat",
            label: "VAT",
            sortable: true,
            sortDirection: "desc",
            class: "text-center"
          },
          {
            key: "raveshare",
            label: "Revenue Share",
            sortable: true,
            sortDirection: "desc",
            class: "text-center"
          },
          {
            key: "techFees",
            label: "Tech Fees",
            sortable: true,
            sortDirection: "desc",
            class: "text-center"
          },
          {
            key: "categories",
            label: "Categories",
            sortable: false,
            sortDirection: "desc",
            class: "text-center",
            formatter: value => {
              return Array.isArray(value) ? value.join(" | ") : value;
            }
          },
          {
            key: "enabled",
            label: "Active?",
            sortable: false,
            sortDirection: "desc",
            class: "text-center"
          },
          {
            key: "deleted",
            label: "Deleted",
            sortable: false,
            sortDirection: "desc",
            class: "text-center align-middle p-0"
          },
          {
            key: "actions",
            label: "Actions",
            sortable: false,
            sortDirection: "desc",
            class: "text-center align-middle p-0"
          }
        ]
      }
    };
  },
  components: {
    Listing
  },
  computed: {},
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
  },
  methods: {
    ...mapGetters(["isAdmin"])
  }
};
</script>
