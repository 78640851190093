<template>
  <v-row>
    <v-col cols="12">
      <b-card
        border-variant="secondary"
        header-border-variant="secondary"
        class="h-100"
      >
        <template v-slot:header>
          <v-row>
            <v-col
              sm="2"
              class="align-middle"
              justify="center"
              v-bind:style="{ margin: '12px auto' }"
              >{{ totalRows }}
              {{ $tc("TYPES." + crudConfig.type + ".LABEL", totalRows) }}</v-col
            >
            <v-col
              :sm="true === showAddNew ? 7 : 10"
              class="align-middle"
              justify="center"
            >
              <v-text-field
                hide-details
                label="Search"
                prepend-inner-icon="search"
                solo
                filled
                color="blue"
                dense
                clearable
                @input="inputSearchHandler"
              ></v-text-field>
            </v-col>
            <b-col
              sm="3"
              class="align-middle text-right"
              v-if="true === showAddNew"
            >
              <v-btn
                class="mr-3"
                icon
                title="v2"
                color="blue darken-2"
                @click="newEntityV2($event.target)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </b-col>
          </v-row>
        </template>

        <v-row
          style="min-height: 740px"
          :align="true === loading ? 'center' : 'start'"
          justify="center"
        >
          <v-col
            cols="12"
            v-if="true === loading"
            class="align-middle text-center"
          >
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </v-col>

          <b-table
            v-if="false === loading"
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :borderless="borderless"
            :outlined="outlined"
            :small="small"
            :hover="hover"
            :dark="dark"
            :fixed="fixed"
            :foot-clone="footClone"
            :no-border-collapse="noCollapse"
            :head-variant="headVariant"
            :table-variant="tableVariant"
            :items="filterItem(items)"
            :fields="crudConfig.fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            @row-clicked="checkboxChecked"
          >
            <template v-slot:cell(checkbox)="row" v-if="true === showCheckbox">
              <v-row>
                <v-col>
                  <b-form-checkbox
                    v-model="row.item.selected"
                    class="align-middle"
                    v-on:change="checkboxChecked(row.item, row.index)"
                  ></b-form-checkbox>
                </v-col>
              </v-row>
            </template>

            <template v-slot:cell(enabled)="row">
              <v-btn
                icon
                color="blue darken-2"
                v-on:click="updateUserBoolField('enabled', row.item, row.index)"
                :loading="row.item.loading.enabled"
                :disabled="user.id === row.item.id"
              >
                <v-icon color="green" v-if="row.item.enabled"
                  >mdi-checkbox-marked-circle</v-icon
                >
                <v-icon color="orange darken-2" v-if="!row.item.enabled"
                  >mdi-cancel</v-icon
                >
              </v-btn>
            </template>

            <template v-slot:cell(name)="row">{{ row.item.name }}</template>

            <template v-slot:cell(actions)="row">
              <router-link
                :to="{
                  name: 'edit_' + crudConfig.type,
                  params: { id: row.item.id }
                }"
                v-slot="{ href, navigate }"
              >
                <v-btn
                  icon
                  color="blue"
                  :href="href"
                  @click="navigate"
                  :title="
                    'Edit ' +
                      $tc('TYPES.' + crudConfig.type + '.LABEL', 1) +
                      ' v2'
                  "
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </router-link>

              <router-link
                :to="{
                  name: 'admin_edit_' + crudConfig.type,
                  params: { id: row.item.externalId }
                }"
                v-slot="{ href, navigate }"
              >
                <v-btn
                  icon
                  color="orange darken-2"
                  :title="
                    'Delete ' + $tc('TYPES.' + crudConfig.type + '.LABEL', 1)
                  "
                  :loading="row.item.loading.deleted"
                  v-on:click="deleteModal('open', row.item, row.index)"
                  v-if="true === showDelete"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </router-link>
            </template>
          </b-table>
        </v-row>

        <template v-slot:footer>
          <b-row>
            <b-pagination
              v-bind:style="{ margin: '0 auto' }"
              align="center"
              justify="center"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-row>
        </template>
      </b-card>
    </v-col>
    <v-col cols="12">
      <v-dialog v-model="dialog.delete.status" width="500" persistent>
        <v-card>
          <v-card-title class="headline"
            >Delete
            {{ $tc("TYPES." + crudConfig.type + ".LABEL", 1) }}</v-card-title
          >

          <v-card-text
            >This {{ $tc("TYPES." + crudConfig.type + ".LABEL", 1) }} will be
            deleted from cache. Are you sure ?</v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text v-on:click="deleteModal('close')"
              >NO</v-btn
            >

            <v-btn
              type="submit"
              class="mx-1 btn"
              style="
                 {
                  border-color: #1c195c;
                  color: #ffffff;
                  background-color: #1c195c;
                }
              "
              title="Delete feed"
              :loading="dialog.delete.loading"
              v-on:click="deleteItem()"
              >YES</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from "@/common/api.service";
import auth from "@/store/auth.module";
import { deleteCompany, getCompaniesV2 } from "@/api/companies/getters";
import axios from "axios";

export default {
  name: "list",
  props: {
    showDelete: {
      type: Boolean,
      default: false
    },
    showAddNew: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    itemsSelected: {
      type: Array,
      default: () => {
        return [];
      }
    },
    crudConfig: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      user: auth.state.user,
      timeout: 25000,
      dialog: {
        delete: {
          status: false,
          loading: false,
          item: null,
          index: -1
        }
      },
      loading: false,
      searchFilter: "",
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 30, 50],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      tableVariants: [
        "primary",
        "secondary",
        "info",
        "danger",
        "warning",
        "success",
        "light",
        "dark"
      ],
      striped: true,
      bordered: false,
      borderless: false,
      outlined: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      footClone: false,
      headVariant: null,
      tableVariant: "",
      noCollapse: false,
      checkboxAll: false,
      elements: []
    };
  },
  components: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.crudConfig.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  created() {},
  mounted() {
    if (false === this.showCheckbox) {
      let fields = this.crudConfig.fields;
      this.crudConfig.fields = fields.slice(1, fields.length);
    }

    this.loadCompanies({ page: 1, search: "" });
  },

  methods: {
    async loadCompanies({ page, search }) {
      this.loading = true;

      try {
        const data = await getCompaniesV2(this.axios, { page, search });

        let elements = [];
        if (data.hasOwnProperty("total_items") && data.hasOwnProperty("data")) {
          this.totalRows = data.total_items;
          this.items = data.data.map(row => {
            return {
              ...row,
              loading: {
                deleted: false,
                active: false
              }
            };
          });
        } else {
          this.totalRows = 1;
          this.items = [];
        }
        this.elements = elements;
        this.checkboxAll = this.elements.length === this.items.length;
        this.loading = false;
      } catch (error) {
        console.error("---ERROR-COMPANIES----");
        console.error(error);
      }
    },
    resetModal(type, item, index) {
      if ("open" === type) {
        this.dialog.reset = {
          status: true,
          item: item,
          index: index
        };
      } else {
        this.dialog.reset = {
          status: false,
          loading: false,
          item: null,
          index: -1
        };
      }
    },
    newEntityV2() {
      this.$router.push(`/${this.crudConfig.type}`);
    },
    updateUserBoolField(field, item) {
      const form = { [field]: !("0" !== item[field]) };

      const items = this.items.map(row => {
        if (item.externalId === row.externalId) {
          return {
            ...row,
            loading: { ...row.loading, [field]: true }
          };
        }

        return row;
      });

      this.items = items;

      ApiService.put(`/v1/${this.crudConfig.apiRoute}/${item.externalId}`, form)
        .then(({ data }) => {
          this.items = this.items.map(row => {
            if (item.externalId === row.externalId) {
              return {
                ...{ ...row, ...data },
                loading: { ...row.loading, [field]: false }
              };
            }

            return row;
          });
        })
        .catch(() => {
          this.items = this.items.map(row => {
            if (item.id === row.id) {
              return {
                ...row,
                loading: { ...row.loading, [field]: false }
              };
            }

            return row;
          });
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    inputSearchHandler(text) {
      if (text && text.length > 2) {
        this.searchFilter = text.toLowerCase();
      } else {
        this.searchFilter = "";
      }
    },
    filterItem(items) {
      let rows = items;

      if (this.searchFilter.length > 0) {
        rows = items.filter(
          item =>
            item.name && item.name.toLowerCase().includes(this.searchFilter)
        );
      }

      this.totalRows = rows.length;

      return rows;
    },
    cutSentence(sentence, len) {
      let cutS = sentence.substring(0, len);
      if (40 < sentence.length) {
        cutS += "...";
      }
      return cutS;
    },
    checkboxCheckedAll() {
      let items = this.items;
      let status = !this.checkboxAll;
      let elements = [];

      const rows = items.map(row => {
        if (status) {
          elements.push(row.id);
        }

        return {
          ...row,
          selected: status
        };
      });

      this.checkboxAll = elements.length === items.length;
      this.elements = elements;
      this.items = rows;
    },
    deleteModal(type, item, index) {
      if ("open" === type) {
        this.dialog.delete = {
          status: true,
          item: item,
          index: index
        };
      } else {
        this.dialog.delete = {
          status: false,
          loading: false,
          item: null,
          index: -1
        };
      }
    },
    async deleteItem() {
      const item = this.dialog.delete.item;
      if (item) {
        this.dialog.delete.loading = true;

        await deleteCompany(axios, item.id);

        this.deleteModal("close");
        await this.loadCompanies({ page: 1, search: "" });
      }
    },
    checkboxChecked(item) {
      let items = this.items;
      let elements = this.elements;
      let status = false;

      const idx = elements.indexOf(item.id);

      if (-1 === idx) {
        elements.push(item.id);
        status = true;
      } else {
        elements.splice(idx, 1);
      }

      const rows = items.map(row => {
        if (item.id === row.id) {
          return {
            ...row,
            selected: status
          };
        }

        return row;
      });

      this.checkboxAll = elements.length === items.length;
      this.elements = elements;
      this.items = rows;
    }
  }
};
</script>
